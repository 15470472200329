<template>
	<div class="container">
		<div class="title">
			<span class="inner_title">二维码生成记录</span>
		</div>
		<div>
			<el-table class="table"
			v-loading="loading"  
			:element-loading-text="loadingText" 
			element-loading-spinner="el-icon-loading"
			:element-loading-background="loadingColor" 
			:data="erCodeList" 
			:header-cell-style="TableTitleBackColor" 
			:row-style="tableCss" 
			:highlight-current-row="true" 
			:cell-style="cellStyle" >
			<el-table-column label="二维码">
				<template slot-scope="scope">
					<img :src="scope.row.img" style="width: 100px;"/>
				</template>
			</el-table-column>
			<el-table-column prop="s_name" label="停车场"></el-table-column>
			<el-table-column prop="eae_title" label="通道口"></el-table-column>
			<el-table-column prop="eae_try" label="类型"></el-table-column>
			<el-table-column prop="type" label="二维码类型"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
				<el-button type="primary" @click="getImgBase(scope.row.img)">下载</el-button>
				<el-button type="warning" style="margin-left: 10px;" @click="deleteErCode(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
			</el-table>
			<el-pagination style="width: 100%;text-align: center;" background layout="prev, pager, next" :page-count="last_page"
			 :current-page="page" @current-change="pageChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	import {
		// Message,
		MessageBox
	} from 'element-ui';
	import {
		mapState
	} from "vuex";
	import {getErCodeListFn,getImgBaseFn,deleteErCodeFn} from '../../api/parking.js'
	import tableCss from '../../style/tableCss.js'
	export default {
		props: [],
		data() {
			return {
				loading:false,
				erCodeList:[],
				page: 1,
				page_size: 10,
				last_page: 0,
			};
		},
		mounted() {
			this.getErCode()
		},
		computed: {
			...mapState(['loadingColor', 'loadingText'])
		},
		methods: {
			//获取二维码列表
			getErCode(){
				this.loading = true;
				getErCodeListFn({
					api_token: localStorage.getItem('token1'),
					page: this.page,
					per_page: this.page_size,
				}).then(res =>{
					if(res.code == 200){
						this.erCodeList = res.datas.data;
						this.last_page = res.meta.last_page;
						this.loading = false;
					}
				})
			},
			getImgBase(imgUrl) {
				getImgBaseFn({
					img_url: imgUrl
				}).then(res => {
					if (res.code == 200) {
						var base64 = res.datas.image_content;
						var link = document.createElement('a');
						link.setAttribute('href', base64);
						link.setAttribute('target', '_blank');
						link.setAttribute('download',imgUrl.split('/').pop());
                        link.click()											
					}
				})
			},
			//删除二维码
			deleteErCode(id){
				this.loading = true;
				deleteErCodeFn({
					api_token:localStorage.getItem('token1'),
					id:id
				}).then(res =>{
					if(res.code == 200) {
						this.$message({
							message: "删除成功",
							type: 'success'
						});
						this.loading = false;
						this.getErCode()
					}
				})
			},
			//页数改变
			pageChange(page) {
				this.page = page;
				this.getErCode();
			},
			//表头样式
			TableTitleBackColor() {
				return tableCss.tableTitleBackColor();
			},
			//表格样式
			tableCss() {
				return tableCss.tableCss();
			},
			//单元格样式
			cellStyle() {
				return tableCss.cellStyle();
			},
		},
	};
</script>
<style scoped>
	@import url("../../style/detail.css");
</style>
